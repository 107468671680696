/**
 * @name: 字典管理
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 字典管理
 * @update: 2023-12-07 16:39
 */
import { get, postJ } from "@/request"

/**
 * 获取字典内容
 * @param types 类型 1-礼品卡规则 2-包邮价 3-运费价 4-不发货地区 5-额外加收运费地区 6-宣传页 7-兑换专区宣传页
 * @returns
 */
export const dictDetailApi = (types: number) => get<{value: string}>("/card/dict/detail", {types})
/**
 * 设置字典内容
 * @param types 类型 1-礼品卡规则 2-包邮价 3-运费价 4-不发货地区 5-额外加收运费地区 6-宣传页 7-兑换专区宣传页
 * @param values 值
 * @returns
 */
export const dictSetUpApi = (types: number, values: string) => postJ("/card/dict/setUp", {types, values})
