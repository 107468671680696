
/**
 * @name: 小程序管理-首页装饰管理
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 小程序管理-首页装饰管理
 * @update: 2023-12-07 16:39
 */
import {Vue, Component} from 'vue-property-decorator';
import config from "@/config";
import {dictDetailApi, dictSetUpApi} from "@/apis/dict";

@Component({})
export default class homeSetting extends Vue {
  config = config

  tableData: any = [
    {
      editTime: '',
      editName: '',
      name: '宣传页',
      pic: '',
      remark: '支持jpg、jpeg、png格式，建议清晰图片1M以内，建议像素710*240px',
      type: 6
    },
    {
      editTime: '',
      editName: '',
      name: '兑换专区宣传页',
      pic: '',
      remark: '支持jpg、jpeg、png格式，建议清晰图片1M以内，建议像素340*382px',
      type: 7
    }
  ]

  tableLoading = false

  async getAllData () {
    this.tableLoading = true
    try {
      const firstRes: any = await dictDetailApi(6)
      if (firstRes) {
        // 宣传页
        this.$set(this.tableData, 0, {
          ...this.tableData[0],
          editTime: firstRes.editTime,
          editName: firstRes.editName,
          pic: firstRes.value ? (config.downloadUrl + (firstRes.value || '')) : ''
        })
      }
    } catch (e) {}
    try {
      const secondRes: any = await dictDetailApi(7)
      if (secondRes) {
        // 兑换专区宣传页
        this.$set(this.tableData, 1, {
          ...this.tableData[1],
          editTime: secondRes.editTime,
          editName: secondRes.editName,
          pic: secondRes.value ? (config.downloadUrl + (secondRes.value || '')) : ''
        })
      }
    } catch (e) {}
    this.tableLoading = false
  }

  beforeUpload (file: File) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 1;

    if (!isJPG) {
      this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 1MB!');
    }
    return isJPG && isLt2M;
  }

  updatePic (res: any, index: number) {
    if (res && res.data && res.code == 0) {
      const url = res.data.url;
      dictSetUpApi(index, url).then(e => {
        if (e) {
          this.$message.success("操作成功!")
          this.getAllData()
        }
      })
    } else {
      this.$message.error('图片上传失败');
    }
  }

  created() {
    this.getAllData()
  }
}
